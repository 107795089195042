.grecaptcha-badge {
    visibility: hidden;
  }
  

.homepage-container {
    background-color: #282828ff;
    max-width: 100%;
    min-height: 100vh;
    text-align: center;
    color: #fff;
    padding: 40px;
    overflow-x: hidden;
    position: relative;
    padding-bottom: 70px;
  }
  
  .tabs-request-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    margin-top: 30px;
    max-width: 100%;
    overflow-x: hidden;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #282828;
  }
  
  .hook-link {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    text-align: center;
    z-index: 1000;
  }
  
  .hook-logo {
    width: 90px;
  }

  @media (max-width: 480px) {
    .homepage-container {
        padding: 0px;
      }
    .bonus-card-container-2{
       padding: 30px; 
    }
    .tabs-request-container {
        margin-top: 5px;
      }
      .hook-link {
        position: relative;
        margin-bottom: 20px;
      }
      
  }
  
  