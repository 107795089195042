.request-history-button {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #e5b645ff;
  border-radius: 8px;
  padding: 12px 30px;
  width: auto;
  cursor: pointer;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #1D1D1D;
  border-radius: 50%;
}

.icon-image {
  width: 20px;
  height: 20px;
}

.button-text {
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  color: #1d1d1dff;
  font-family: 'SF Pro Text Medium', sans-serif;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  backdrop-filter: blur(7px);
}

.modal-content {
  border-radius: 12px;
  position: relative;
  width: 1000px; /* Bu genişlik mobilde değiştirilecek */
  max-width: 100%;
}


@media (max-width: 768px) {
  .modal-content {
    width: 90%;
  }
  .request-history-button {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #e5b645ff;
    border-radius: 8px;
    padding: 8px 10px;
    width: auto;
    cursor: pointer;
  }
  .icon-image {
    width: 15px;
    height: 15px;
  }
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: #1D1D1D;
    border-radius: 50%;
  }
  
  .button-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1d1d1dff;
    font-family: 'SF Pro Text Medium', sans-serif;
  }
}

@media (max-width: 480px) {
  .modal-content {
    width: 95%;
  }
}
