/* Alert bileşeninin genel stili */
.snackbar-alert {
    max-width: 600px;
    width: 600px;
    height: 70px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Dinamik ikonun boyutu */
  .snackbar-alert-icon {
    width: 35px;
    height: 35px;
  }
  
  /* CircularProgress stil düzenlemesi */
  .circular-progress {
    margin-right: 8px;
  }
  
  /* Snackbar içindeki mesajın stili */
  .snackbar-title {
    display: flex;
    margin-top: 6px;
  }
  
  .snackbar-message {
    display: flex;
    font-weight: 400;
    margin-top: -4px;
  }
  
  /* Mobil uyumluluk */
  @media (max-width: 768px) {
    .snackbar-alert {
      width: 90%; /* Mobilde genişliği %90 yap */
      font-size: 0.9rem; /* Yazı boyutunu mobilde biraz küçült */
      height: auto;
    }
  
    .snackbar-alert-icon {
      width: 30px;
      height: 30px;
    }
  
    .circular-progress {
      margin-right: 6px;
    }
  
    .snackbar-title, .snackbar-message {
      flex-direction: column; /* Mesajları dikey hizala */
      margin-top: 0;
    }
  }
  
  @media (max-width: 480px) {
    .snackbar-alert {
      width: 90%; /* Mobilde genişliği %90 yap */
      font-size: 0.9rem; /* Yazı boyutunu mobilde biraz küçült */
      height: auto;
    }
  
    .snackbar-alert-icon {
      width: 25px;
      height: 25px;
    }
  
    .circular-progress {
      margin-right: 6px;
    }
  
    .snackbar-title, .snackbar-message {
      flex-direction: column; /* Mesajları dikey hizala */
      margin-top: 0;
    }
    
  }