.double-bonus {
    width: 1000px; /* Dialog genişliği */
    height: 550px; /* Yüksekliği artırıldı */
    background: #1d1d1d; /* Daha koyu opak arka plan */
    padding: 50px;
    box-sizing: border-box;
    position: relative;
    border-radius: 50px; /* Yuvarlatılmış köşeler */
    overflow: hidden;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    max-height: 650px; /* Sabit bir maksimum yükseklik */
    overflow-y: auto; /* Dikey kaydırma etkinleştir */
  }

  .double-bonus {
    scrollbar-width: none; /* Firefox için scrollbar gizle */
  }
  
  .double-bonus::-webkit-scrollbar {
    display: none; /* WebKit (Chrome, Safari) için scrollbar gizle */
  }
  
  
  .DoubleBonusheader {
    display: flex;
    justify-content: space-between;
    font-weight: 700; /* Font ağırlığı azaltıldı */
    font-size: 32px; /* Biraz küçültüldü */
    line-height: 36px;
  }
  
  .DoubleBonusCloseIcon {
    position: fixed; /* Sabit pozisyon */
    right: 20px; /* Sağdan boşluk */
    top: 20px; /* Yukarıdan boşluk */
    z-index: 1000; /* Her şeyin üzerinde görünsün */

  }
  
  
  
  .empty-state1 {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: center;
  }

  
  .empty-state1 p {
    font-size: 24px;
    color: #959595;
    font-weight: 500;
  }
  
  .empty-subtext1 {
    font-size: 24px;
    color: #959595;
    font-weight: 500;
  }


  .bonus-icon1 {
    width: 90px;
    height: 90px;
    margin-bottom: -15px;
  }
  
  
  .buttons {
    margin-top: 20px;
  }
  
  .button-primary,
  .button-secondary {
    width: 100%;
    max-width: 350px;
    padding: 22px 28px;
    font-size: 20px;
    font-weight: 720;
    letter-spacing: 0.1px;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .button-primary {
    background-color: #e5b645;
    border: none;
    color: #25282b;
    margin-bottom: 8px;
  }
  
  .button-secondary {
    background-color: #1d1d1d;
    border: 1px solid #e5b645;
    color: #e5b645;
  }
  
  .separator {
    display: block;
    margin: 8px;
    margin-bottom: 16px;
    color: #cacaca;
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.1px;
  }
  
  @media (max-width: 480px) {
    .double-bonus {
      width: 100%;
      height: 600px;
      padding: 20px;
      border-radius: 15px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
      background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
    }
  
    .DoubleBonusheader {
      font-size: 22px;
      text-align: center;
      margin-bottom: 20px;
      font-weight: 700;
    }

    
  .bonus-icon1 {
    width: 80px;
    height: 80px;
    margin-bottom: -15px;
  }
  
    .line-section {
      display: none;
    }
  
    .empty-state1 {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      text-align: center;
    }
  
  
    .empty-state1 p {
      font-size: 20px;
      color: #b0b0b0;
      font-weight: 500;
    }
  
    .empty-subtext1 {
      font-size: 18px;
      color: #b0b0b0;
      font-weight: 500;
    }
  }

  @media (max-width: 400px) {
    .double-bonus {
      width: 100%;
      height: 480px;
      padding: 20px;
      border-radius: 15px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
      background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
    }

    .button-primary,
    .button-secondary {
      width: 100%;
      max-width: 350px;
      padding: 18px 20px;
      font-size: 16px;
      font-weight: 720;
      letter-spacing: 0.1px;
      border-radius: 6px;
      cursor: pointer;
    }

    .bonus-icon1 {
      width: 70px;
      height: 70px;
      margin-bottom: -15px;
    }
  
    .DoubleBonusheader {
      font-size: 22px;
      text-align: center;
      margin-bottom: 20px;
      font-weight: 700;
    }
  
    .line-section {
      display: none;
    }
  
    .empty-state1 {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      text-align: center;
    }
  
  
    .empty-state1 p {
      font-size: 16px;
      color: #b0b0b0;
      font-weight: 500;
    }
  
    .empty-subtext1 {
      font-size: 16px;
      color: #b0b0b0;
      font-weight: 500;
    }

    .separator {
      display: block;
      margin: 4px;
      margin-bottom: 8px;
      color: #cacaca;
      font-style: italic;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.1px;
    }
  }