.rulesMain {
  width: 800px;
  max-height: 550px; /* Maksimum yükseklik %80 olacak şekilde ayarlanıyor */
  background: #1e1e1e;
  padding: 50px;
  box-sizing: border-box;
  position: relative;
  border-radius: 16px;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  overflow-y: auto; /* İçerik fazla olduğunda dikey kaydırma çubuğu ekleniyor */
}

.rulesMain {
  scrollbar-width: none; /* Firefox için scrollbar gizle */
}

.rulesMain::-webkit-scrollbar {
  display: none; /* WebKit (Chrome, Safari) için scrollbar gizle */
}


.RulesHeader {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 20px;
  font-family: 'SF Pro Text Medium', sans-serif;
}

.rulesCloseIcon {
  position: fixed; /* Sabit pozisyon */
  right: 20px; /* Sağdan boşluk */
  top: 20px; /* Yukarıdan boşluk */
  z-index: 1000; /* Her şeyin üzerinde görünsün */

}



.rules-content {
  margin-top: 20px;
}

.accordion {
  margin-bottom: 20px;
}

.accordion-item {
  border: 1px solid #444;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #2a2a2a;
}

.accordion-title {
  padding: 15px 20px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  background-color: #333;
  color: #e5b645;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
  font-family: 'SF Pro Text Medium', sans-serif;
}

.accordion-title:hover {
  background-color: #444;
}

.accordion-content {
  padding: 15px 20px;
  background-color: #2a2a2a;
  font-size: 16px;
  line-height: 24px;
  color: #e1e1e1;
  font-family: 'SF Pro Text Medium', sans-serif;
  max-height: 200px; /* Accordion içerik yüksekliği sınırlandırıldı */
  overflow-y: auto; /* İçerik fazla olduğunda kaydırma çubuğu ekleniyor */
}

/* Kaydırma çubuğu için genel stil */
.accordion-content::-webkit-scrollbar {
  width: 8px; /* Kaydırma çubuğu genişliği */
}

/* Kaydırma çubuğu arka planı */
.accordion-content::-webkit-scrollbar-track {
  background: #1e1e1e; /* Arka plan koyu gri */
}

/* Kaydırma çubuğu için stil */
.accordion-content::-webkit-scrollbar-thumb {
  background-color: #555; /* Koyu gri tonlarında kaydırma çubuğu */
  border-radius: 10px; /* Yuvarlak köşeler */
}

/* Hover durumunda kaydırma çubuğu */
.accordion-content::-webkit-scrollbar-thumb:hover {
  background-color: #888; /* Üzerine gelindiğinde biraz daha açık gri */
}

.accordion-content div {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .rulesMain {
      width: 100%;
      padding: 20px;
      border-radius: 15px;
  }

  .RulesHeader {
      font-size: 24px;
  }

  .accordion-title {
      font-size: 16px;
  }
}
